import heroImg from "../assets/img/home/mph.svg";
import heroImgMobile from "../assets/img/home/mph-mobile.svg";

const homeContent = {
        heroImage: heroImg,
        heroMobileImage: heroImgMobile,
        heroTitleName: "MINH PHÚC HÂN",
        heroDesignation: "",
        heroDescriptions: `Sáng tạo & Đổi mới. Sự sáng tạo kết hợp với sự đổi mới và tạo ra sự
        hài lòng của người dùng và khách hàng. Chúng tôi tuân theo kiến trúc ứng dụng hiện đại ngay từ đầu. <br/>Chúng ta hãy cùng nhau tạo ra một cái gì đó tuyệt vời.<br/>Hotline: <b>0911769049 | 0822640668</b> Mail: <b>contact@hoa.name.vn</b>`,
        heroBtn: "Tại sao chọn chúng tôi?",
};


export default homeContent