import Image1 from "../assets/img/portfolio/b3e.png";
import Image2 from "../assets/img/portfolio/addns.png";
import Image6 from "../assets/img/portfolio/taskmanager.jpg";

import Image3 from "../assets/img/portfolio/project-2.jpg";
import Image4 from "../assets/img/portfolio/project-2.jpg";
import Image5 from "../assets/img/portfolio/moneytime.png";

const PortfolioData = [
  {
    id: 1,
    type: "Business 365 Essentials",
    image: Image1,
    tag: ["desktop"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Desktop",
        client: "15 ngày miễn phí",
        language: "Quản lý kinh doanh",
        preview: "business365.hoa.name.vn",
        link: "https://business365.hoa.name.vn/",
      },
    ],
    typeImage: 1
  },
  {
    id: 2,
    type: "ADDNS Auto Update",
    image: Image2,
    tag: ["desktop"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Desktop",
        client: "Miễn phí",
        language: "Cập nhật DNS IP ISP",
        preview: "addns.hoa.name.vn",
        link: "https://addns.hoa.name.vn",
      },
    ],
    typeImage: 1
  },  
  {
    id: 3,
    type: "Mobile Money Time",
    image: Image5,    
    tag: ["mobile"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Mobile",
        client: "Miễn phí",
        language: "Quản lý tài chính cá nhân",
        preview: "moneytime.hoa.name.vn",
        link: "https://moneytime.hoa.name.vn",
      },
    ],    
    typeImage: 1
  },
  {
    id: 4,
    type: "Mobile Focus Task",
    image: Image6,
    tag: ["mobile", "web"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Mobile",
        client: "Miễn phí",
        language: "Tập trung nhiệm vụ",
        preview: "focus.hoa.name.vn",
        link: "https://focus.hoa.name.vn",
      },
    ],
    typeImage: 3
  },
  
  
];

export const ImageList = [
    {
      id:1,
      idImage:3,
      subImage: Image3
    },
    {
      id:1,
      idImage:3,
      subImage: Image4
    },
    {
      id:1,
      idImage:3,
      subImage: Image5
    },
]

export default PortfolioData;
