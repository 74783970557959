import React from "react"

import { IntroComponent, CompaneyImage } from "./intro";
import { QuoteComponent } from "./quote-content";
import {DevModule, DevModuleImage} from "./devModule";
import { InnivateComponent, InnovateImage } from "./innovate";

const WhyChooseUs = () =>{
      return(
            <>
                  <section className="main-content ">
                        <div className="container">

                              <div className="row">                                    
                                    <div className="col-lg-8 m-15px-tb">
                                          <div className="resume-box">
                                                <IntroComponent/>                                          
                                          </div>
                                    </div>
                                    <div className="col-lg-4 mt-4">
                                          <div className="resume-box">
                                                <img src={CompaneyImage} alt="Thử nghiệm thúc đẩy phát triển" />
                                          </div>
                                    </div>
                              </div>
                              <hr className="separator mt-1"/>
                              {/* Top Ends */}
                              
                              <div className="row">
                                    <div className="col-12">
                                          <div className="resume-box">
                                                <QuoteComponent/>      
                                          </div>
                                    </div>
                              </div>
                              <hr className="separator mt-1" />
                              {/* Qoute Ends */}
                              
                              <div className="row">
                                    <div className="col-12">
                                          <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
                                          Chúng tôi đổi mới cho bạn
                                          </h3>
                                    </div>
                                    <div className="col-lg-4 m-15px-tb">
                                          <div className="resume-box">
                                                <img src={InnovateImage} alt="Chúng tôi đổi mới cho bạn" />                                    
                                          </div>
                                    </div>
                                    <div className="col-lg-8 m-15px-tb">
                                          <div className="resume-box">
                                                <InnivateComponent/>      
                                          </div>
                                    </div>
                              </div>
                              <hr className="separator mt-1" />
                              {/* Skills Ends */}
                              
                              <div className="row">
                                    <div className="col-12">
                                          <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
                                          Thử nghiệm thúc đẩy phát triển
                                          </h3>
                                    </div>
                                    <div className="col-lg-6 m-15px-tb">
                                          <div className="resume-box">
                                                <DevModule/>                                          
                                          </div>
                                    </div>
                                    <div className="col-lg-6 mt-4">
                                          <div className="resume-box">
                                                <img src={DevModuleImage} alt="Thử nghiệm thúc đẩy phát triển" />
                                          </div>
                                    </div>
                              </div>
                              {/*  Experience & Education Ends */}
                        </div>
                  </section>
            </>
      );
}

export default WhyChooseUs