import React, { useState } from "react";
import Modal from "react-modal";

import homeContent from "../../Hooks/Home";
import WhyChooseUs from "./whychooseus";
import cancelImg from "../../assets/img/cancel.svg";

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="row home-details-container align-items-center">
        <div className="col-lg-4 bg position-fixed d-none d-lg-block"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + homeContent.heroImage
            })`,
          }}
        ></div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
          <div>

            <img className="img-fluid main-img-mobile d-sm-block d-lg-none"
              src={homeContent.heroMobileImage}              
              alt=""
            />

            <h1 className="text-uppercase poppins-font">
              {homeContent.heroTitleName}
              <span>{homeContent.heroDesignation}</span>
            </h1>

            <p className="open-sans-font" dangerouslySetInnerHTML={{__html: homeContent.heroDescriptions}}></p>

            <div className="row">
              <div className="col-xl-6 col-12 col-lg-5">
                <button className="button" onClick={toggleModalOne}>
                  <span className="button-text">{homeContent.heroBtn}</span>
                  <span className="button-icon fa fa-arrow-right"></span>
                </button>
              </div>             
            </div>
            
          </div>
        </div>
      </div>
      {/* End home-details-container */}

      {/* Start Modal for About More */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal dark hero"
        overlayClassName="custom-overlay dark"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src={cancelImg} alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner about">
            <div data-aos="fade-up" data-aos-duration="1200">
            
              <div className="title-section text-left text-sm-center ">
                <h1>
                  choose <span>us?</span>
                </h1>
                <span className="title-bg">why</span>
                <h3 className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
                  <span>TẠI SAO CHỌN CHÚNG TÔI?</span>                  
                </h3>                               
              </div>

              {/* <Index /> */}
              <WhyChooseUs/>
              
            </div>
          </div>

        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for About More */}
    </>
  );
};

export default Hero;
