import React from "react";

import InnovateImage from "../../assets/img/home/working-innovate.svg"

const InnovateContent = [
  {
    year: "   ",
    position: "Không bao giờ ngừng học hỏi",
    compnayName: "Envato",
    details: `Chúng tôi không ngừng phát triển các sản phẩm của mình để đạt được các tiêu chuẩn cao hơn nữa về thiết kế, chất lượng, sản xuất và bền vững môi trường.`,
  },
  {
    year: "",
    position: "Chất lượng hơn số lượng",
    compnayName: "",
    details: `Chúng tôi tự hào về những gì chúng tôi làm, tin tưởng vào công việc của chúng tôi và được thúc đẩy bởi sự thành công của khách hàng.`,
  }
]

const InnivateComponent = () => {
  return (
    <ul>
      {InnovateContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-check-square-o"></i>
          </div>
          {/* <span className="time open-sans-font text-uppercase">{val.year}</span> */}
          <h5 className="poppins-font text-uppercase">
            {val.position}
            {/* <span className="place open-sans-font">{val.compnayName}</span> */}
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export {
  InnivateComponent,
  InnovateImage
};
