import React from "react";
import Slider from "react-slick";
import portfolioVideo from "../../../assets/img/portfolio/video.mp4";

//import Image3 from "../../../assets/img/portfolio/project-2.jpg";
//import { ImageList } from "../portfolioData";

const TypeFigue = ({typeFigue, item}) => {
     var settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: true,
     };

     if(typeFigue === 1){ //Single Image
          return(
               <figure className="modal__img">
                  <img src={item.image} alt={item.type} />
               </figure>
          );          
     }
     else if(typeFigue === 2){  //mutiple image
         
          return(
               <figure className="modal__img">
                  <Slider {...settings}>
                    {item.image.map((val) => (
                              // <li key={i}>
                                   <div>
                                        <img src={val.subImage} alt={item.type} />
                                   </div>
                              // </li>
                              
                         ))
                    }
                    
                    {/* <div>
                      <img src={Image3} alt="portfolio project demo" />
                    </div>
                    <div>
                      <img src={Image3} alt="portfolio project demo" />
                    </div>
                    <div>
                      <img src={Image3} alt="portfolio project demo" />
                    </div> */}
                  </Slider>
                </figure>
          );
     }
     else if(typeFigue === 3){
          return(
               <figure className="modal__img">
                  {/* <img src={item.image} alt="portfolio project demo" /> */}
                  <video
                    id="video"
                    className="responsive-video"
                    controls
                    poster={item.image}
                  >
                    <source src={portfolioVideo} type="video/mp4" />
                    {/* <source src="img/" type="video/mp4" /> */}
                  </video>
               </figure>
          );
     }
}

const ItemModal = ({item, typeFigue = 1}) => {
     return(
          <>
               <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-2">
                          <i className="fa fa-file-text-o pr-2"></i>
                          Project:{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.project}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-2">
                          <i className="fa fa-user-o pr-2"></i>
                          Giá :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.client}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-2">
                          <i className="fa fa-code pr-2"></i>
                          Mô tả :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.language}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-2">
                          <i className="fa fa-external-link pr-2"></i>
                          Xem :{" "}
                          <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.link}
                          >
                            {details.preview}
                          </a>
                        </div>
                      </div>
                    );
                  })}
               </div>
               
               <TypeFigue typeFigue={typeFigue} item={item}/>
          </>
     );
}

export default ItemModal