import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Footer from "../components/Footer";
import Hero from "../components/home/Hero";
import Index from "../components/about/index";
import Address from "../components/Address";
import Portfolio from "../components/portfolio/Portfolio";
import Blog from "../components/blog/Blog";
import Contact from "../components/Contact";
import Social from "../components/Social";
import SwitchDark from "../components/switch/SwitchDark";
//import Map from "../components/Map";
import { ContactAbout } from "../Hooks/dataComon";

const menuItem = [
  { icon: "fa-home", menuName: "Trangchủ" },
  { icon: "fa-user", menuName: "Giớithiệu" },
  { icon: "fa-briefcase", menuName: "SẢNPHẨM" },
  { icon: "fa-codepen", menuName: "Dịchvụ" },
  { icon: "fa-envelope-open", menuName: "Liênhệ" },
];

const HomeDark = () => {
  document.querySelector("body").classList.add("light");

  return (
    <div className="blue">

      <SwitchDark />
      {/* End Switcher */}

      <Tabs>

        <div className="header">
          <TabList className=" icon-menu  revealator-slideup revealator-once revealator-delay1">
            {menuItem.map((item, i) => (
              <Tab className="icon-box" key={i}>
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Tab>
            ))}
          </TabList>
        </div>
        {/* End Menu Content */}

        <div className="tab-panel_list">

          <TabPanel className="home ">
            <div className="container-fluid main-container container-home p-0 "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="color-block d-none d-lg-block"></div>
              <Hero />
            </div>
          </TabPanel>
          {/* Hero Content Ends */}

          <TabPanel className="about">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center">
                <h1>
                  <span>MINH PHÚC HÂN</span>
                </h1>
                <span className="title-bg">ABOUT</span>
              </div>
              <Index />
            </div>
          </TabPanel>
          {/* About Content Ends */}

          <TabPanel className="portfolio professional">
            <div className="title-section text-left text-sm-center" data-aos="fade-up" data-aos-duration="1200">
              <h1 className="open-sans-font">
                DANH <span>MỤC</span>
              </h1>
              <span className="title-bg open-sans-font">SẢN PHẨM</span>
            </div>
            <Portfolio />
          </TabPanel>
          {/* Production Content Ends */}

          <TabPanel className="blog">
            <div className="title-section text-left text-sm-center open-sans-font"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                <span>dịch vụ</span>
              </h1>
              <span className="title-bg">DANH MỤC</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              {/*  Articles Starts  */}
              <div className="row pb-50">
                <Blog />
              </div>
              {/* Articles Ends */}
            </div>
          </TabPanel>
          {/* Service Content Ends */}

          <TabPanel className="contact">
            <div className="title-section text-left text-sm-center"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <h1>
                   <span>chúng tôi</span>
                </h1>
                <span className="title-bg">liên hệ</span>
              </div>
              <div
                className="container"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="row">
                  {/*  Left Side Starts */}
                  <div className="col-12 col-lg-4">
                    <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                      {ContactAbout.companyName}
                    </h3>
                    <p className="open-sans-font mb-4" dangerouslySetInnerHTML={{__html: ContactAbout.discription}}/>
                    <Address />
                    <Social />
                  </div>
                  {/* Left Side Ends */}

                  <div className="col-12 col-lg-8">
                    {/* <Map /> */}
                    <Contact/>
                    {/* map google here */}
                  </div>
                  
                </div>
              </div>

          </TabPanel>
          {/* Contact Content Ends */}
          
        </div>
      </Tabs>
      
      <Footer/>
    </div>
  );
};

export default HomeDark;
