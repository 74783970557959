import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CloseImg from "../../../assets/img/cancel.svg";
import PortfolioData from "../../../Hooks/portfolioData";

import ItemModal from "./Item";

const Modal = ({ modalId, setGetModal }) => {

  

  if (modalId === 1) {
    return (
      <div className="modal_portfolio ">
        <div className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">
                  {item.type}
                </h2>
                <ItemModal item={item} typeFigue={item.typeImage}/>
                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 2) {
    return (
      <div className="modal_portfolio">
        <div className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">
                  {item.type}
                </h2>
                <ItemModal item={item} typeFigue={item.typeImage}/>
                <button className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt={item.type} />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 3) {
    return (
      <div className="modal_portfolio">
        <div className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">
                  {item.type}
                </h2>
                <ItemModal item={item} typeFigue={item.typeImage}/>
                <button className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt={item.type} />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 4) {
    return (
      <div className="modal_portfolio">
        <div className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
              return (
                <div key={item.id} data-aos="fade">
                  <h2 className="heading mb-2">
                    {item.type}
                  </h2>
                  <ItemModal item={item} typeFigue={item.typeImage}/>

                  <button className="close-modal"
                    onClick={() => setGetModal(false)}
                  >
                    <img src={CloseImg} alt={item.type} />
                  </button>
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  } 
};

export default Modal;
