import React from "react";
import { Routes, Route } from "react-router-dom";

import NotFound from "../views/NotFound";
import Home from "../views/Home";
import PrivacyPolicyContent from "../views/PrivacyPolicy";
// import TermsofUse from "../views/TermsofUse";
// import SupportPolicyContent from "../views/SupportPolicy";

import ContextProvider from "../Context/ContextProvider";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const AllRoutes = () => {
  return (
    <>
      <ContextProvider>
        <ScrollTopBehaviour />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/terms-of-use" element={<TermsofUse />} /> */}
          <Route path="/privacy-policy" element={<PrivacyPolicyContent />} />
          {/* <Route path="/support-policy" element={<SupportPolicyContent />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ContextProvider>
    </>
  );
};

export default AllRoutes;
