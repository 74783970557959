import React from "react";

import DevModuleImage from "../../assets/img/home/organizing-projects.svg"

const devContent = [
  {
    year: "   ",
    position: " Phát triển nhanh nhẹn",
    compnayName: "",
    details: `Chúng tôi liên tục thử nghiệm các công nghệ mới và triển khai chúng trong các dự án của mình để tạo ra kết quả lớn hơn. Thực tiễn và giải pháp của chúng tôi rất đơn giản, bởi vì đơn giản = rõ ràng = dễ hiểu và có thể thực thi.`,
  },
  {
    year: "",
    position: " Phương pháp tiếp cận mô-đun",
    compnayName: "",
    details: `Chúng tôi theo đuổi kiến trúc ứng dụng hiện đại ngay từ đầu. Đó là lý do tại sao các ứng dụng của chúng tôi dễ bảo trì hơn, dễ kiểm tra và ít bị lỗi hơn, giúp bạn tiết kiệm cả thời gian và chi phí.`,
  }
]

const DevModule = () => {
  return (
    <ul>
      {devContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-connectdevelop"></i>
          </div>
          {/* <span className="time open-sans-font text-uppercase">{val.year}</span> */}
          <h5 className="poppins-font text-uppercase">
            {val.position}
            {/* <span className="place open-sans-font">{val.compnayName}</span> */}
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export {
  DevModule,
  DevModuleImage
};
