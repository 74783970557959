import React from "react";
import { experienceContent } from "../../Hooks/about";


const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-lightbulb-o"></i>
          </div>
          {/* <span className="time open-sans-font text-uppercase">{val.year}</span> */}
          <h5 className="poppins-font text-uppercase">
            {val.position}
            {/* <span className="place open-sans-font">{val.compnayName}</span> */}
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
