import React from "react"

const Footer = () => {
     return(
          <>
              <footer>
                    <div className="text-center footer_copyright open-sans-font">
                         <h5>
                              &copy; {new Date().getFullYear()} Thiết kế bởi{" "}
                              <span className="heart">&#10084;</span> by{" "}
                              <a
                                   href="/"
                                   rel="noreferrer"
                              >
                                   Minh Phúc Hân
                              </a>{" [ "}
                              {/* <a
                                   href="/terms-of-use"
                                   rel="noreferrer"
                              >
                                   Điều khoản sử dụng
                              </a>{" | "} */}
                              {/* <a
                                   href="/support-policy"
                                   rel="noreferrer"
                              >
                                   Chính sách hỗ trợ
                              </a>{" | "} */}
                              <a
                                   href="/privacy-policy"
                                   rel="noreferrer"
                              >
                                   Chính sách bảo mật
                              </a>{" ]"}
                         </h5>
                    
                    </div>
               </footer>
          </>
     );
}

export default Footer