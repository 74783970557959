import React from "react";

import { PrivacyPolicy } from "../Hooks/dataComon";
import Footer from "../components/Footer";

const PrivacyPolicyContent = () => {
  document.querySelector("body").classList.add("light");

  return (
    <div className="about">
        <div data-aos="fade-up" data-aos-duration="1200">
          <div className="mt-5"></div>
          <section className="main-content ">
            <div className="container">

              <div className="col-12">
                <h3 className="text-uppercase custom-title mb-0 ft-wt-600 text-sm-center">
                  {PrivacyPolicy.title}
                </h3>
              </div>

              <div className="col-12">
                <p  className="open-sans-font" 
                    dangerouslySetInnerHTML={{__html: PrivacyPolicy.content}}>
                </p>
              </div>
                    
            </div>
          </section>
        </div>

      <Footer/>
    </div>
  );
};

export default PrivacyPolicyContent;
