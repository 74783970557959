import React from "react";
import Modal from "react-modal";

import cancelImg from "../../assets/img/cancel.svg";
//import blogQuote from "../../assets/img/blog/quote.svg";

const ServiceModal = ({isOpen, setIsOpen, singleData}) => {
     return (
          <Modal
              isOpen={isOpen}
              onRequestClose={() => setIsOpen(false)}
              contentLabel="My dialog"
              className="custom-modal dark"
              overlayClassName="custom-overlay dark"
              closeTimeoutMS={500}
          >
            <div className="" >
              <button
                className="close-modal"
                onClick={() => setIsOpen(false)}
              >
                <img src={cancelImg} alt="close icon" />
              </button>
              {/* End close icon */}

              <div className="box_inner blog-post">
                {/* Article Starts */}
                <article>
                  {/* <div className="title-section text-left text-sm-center">
                    <h1>
                      Post <span>Details</span>
                    </h1>
                    <span className="title-bg">posts</span>
                  </div> */}
                  {/* Meta Starts */}

                  <div className="meta open-sans-font mt-5">
                    <span>
                      <i className="fa fa-user"></i> {singleData.commentor}
                    </span>
                    <span className="date">
                      <i className="fa fa-calendar"></i> {singleData.date}
                    </span>
                    <span>
                      <i className="fa fa-tags"></i> {singleData.tag}
                    </span>
                  </div>
                  {/* Meta Ends */}
                  {/* Article Content Starts */}

                  <h1>{singleData?.title}</h1>
                  <img
                    src={singleData?.img}
                    className="img-fluid"
                    alt="Blog"
                  />
                  <div className="blog-excerpt open-sans-font pb-5">
                    <p>{singleData?.description1}</p>
                    {/* <div className="quotebox">
                      <div className="icon">
                        <img src={blogQuote} alt="blog quote" />
                      </div>
                      <p>{singleData?.description2}</p>
                    </div> */}
                    <p>{singleData?.description2}</p>
                    <p>{singleData?.description3}</p>
                    <p>{singleData?.description4}</p>
                  </div>
                  {/* Article Content Ends */}
                </article>
                {/* Article Ends */}
              </div>
            </div>
          </Modal>
     );
}

export default ServiceModal