import React from "react";

import { addressContent } from "../Hooks/dataComon";


const Address = () => {
  return (
    <>
      {addressContent.map((val, i) =>(
        <p className="open-sans-font custom-span-contact position-relative" key={i}>
          <i className={val.iconName}></i>
          <span className="d-block">{val.title}</span>{" "}
          <a href={val.link}>{val.addressContent}</a>
        </p>  
      ))}
    </>
  );
};

export default Address;
