import React from "react";
import Education from "./Education";
import Experience from "./Experience";

import { aboutTop, aboutTopImage, aboutTopImageMobile } from "../../Hooks/about";


const index = () => {
  return (
    <section className="main-content ">
      <div className="container">

        <div className="row">

          <div className="col-xl-8 col-lg-5 col-12">
            <div className="row">
              <div className="col-12">
                <h3 className="text-uppercase custom-title mb-0 ft-wt-600">
                Sáng tạo & Đổi mới
                </h3>
              </div>

              <div className="col-12 d-block d-sm-none">
                <img
                  src={aboutTopImageMobile}
                  className="img-fluid main-img-mobile"
                  alt="about avatar"
                />
              </div>
              {/* image for mobile menu */}

              <div className="col-12">
                <p  className="open-sans-font" 
                    dangerouslySetInnerHTML={{__html: aboutTop.content}}>
                </p>
              </div>
              {/* End personal info */}
              
            </div>
          </div>
          {/*  Personal Info Ends */}

          <div className="col-xl-4 col-lg-7 col-12 mt-5 mt-lg-0">
                <img
                  src={aboutTopImage}
                  className="img-fluid main-img-mobile"
                  alt="about avatar"
                />
          </div>
          {/* Achievements Ends */}
        </div>
        <hr className="separator mt-1 "/>
        {/* Intro Ends */}

        <div className="row">
          <div className="col-lg-6 m-15px-tb">
            <div className="resume-box">
              <Experience />
            </div>
          </div>
          <div className="col-lg-6 m-15px-tb">
            <div className="resume-box">
              <Education />
            </div>
          </div>
        </div>
        {/* <hr className="separator mt-1" /> */}
        {/*  Experience & Education Ends */}

        {/* <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
            vấn đề khác biệt
            </h3>
          </div>
        </div> */}
       
      </div>
    </section>
  );
};

export default index;
