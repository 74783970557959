import React from "react";

import QuoteImage from "../../assets/img/home/dev.png"

const QuoteContent = [
  {
    position: "STEVE JOBS",
    compnayName: "Apple",
    details: `Design is not what it looks like and feels like.
    Design is how it works <br/> Thiết kế không phải là những gì nó trông giống như và cảm thấy như thế nào. Thiết kế là cách nó hoạt động`,
  }  
]

const QuoteComponent = () => {
  return (
    <ul>
      {QuoteContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-quote-left"></i>
          </div>
          {/* <span className="time open-sans-font text-uppercase">{val.year}</span> */}
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font" dangerouslySetInnerHTML={{__html: val.details}}></p>
          <div className="icon">
            <i className="fa fa-quote-right"></i>
          </div>
        </li>
      ))}
    </ul>
  );
};

export {
     QuoteComponent,
     QuoteImage
};
