import { useState } from "react";
import img1 from "../assets/img/blog/Hand-coding.svg";
import img2 from "../assets/img/blog/Site-Stats.svg";
import img3 from "../assets/img/blog/Website-Creator.svg";


const AllBlogData = () => {
  const blogsData = [
    {
      id: 1,
      img: img1,
      title: "Phát triển web",
      commentor: "Mrs Diệu ",
      date: "21-10-2022",
      tag: `laravel, .net framwork, css, html`,
      description1:
        "Chúng tôi cung cấp các dịch vụ để phát triển các ứng dụng web hiện đại bằng cách sử dụng các công nghệ và quy trình mới nhất.",
      description2:
        "Chúng tôi có thể giúp bạn phát triển toàn bộ ứng dụng từ đầu, thêm tính năng cho các sản phẩm hiện có hoặc di chuyển các ứng dụng cũ sang công nghệ mới.",
      description3:
        "Nếu bạn đang tìm kiếm một đối tác có thể hướng dẫn bạn trong quá trình phát triển web, thì chúng tôi là nhóm bạn đang tìm kiếm.",
      description4:
        "Sức mạnh của chúng tôi là sự hiểu biết của chúng tôi về các doanh nghiệp - vì vậy trang web của bạn sẽ không chỉ tuyệt vời mà còn giúp bạn thu hút nhiều khách hàng và lợi nhuận hơn.",
    },
    {
      id: 2,
      img: img2,
      title: "Trang web thương mại điện tử",
      commentor: "Santhan ",
      date: "27-10-2022",
      tag: `shop, business, e-com`,
      description1:
        "Chúng tôi xây dựng các trang web Thương mại điện tử riêng biệt để nhắm mục tiêu cụ thể vào các thị trường cốt lõi và tạo ra trải nghiệm khách hàng vừa độc đáo vừa bổ ích - đảm bảo khách hàng hài lòng.",
      description2:
        "Bạn xây dựng dòng sản phẩm của mình, chúng tôi sẽ xây dựng cửa hàng của bạn. Hãy để người mua xem những gì trong cửa hàng của riêng bạn.",
      description3:
        "Chúng tôi sẽ đồng hành cùng bạn trong toàn bộ quá trình, bắt đầu từ phân tích, thông qua phát triển nền tảng e-com đến kế hoạch tiếp thị kỹ thuật số và thống kê hiệu suất.",
      description4:
        "Một trang web Thương mại điện tử chuyên nghiệp, dễ sử dụng và được bảo trì tốt là chìa khóa chính để tạo ra một doanh nghiệp trực tuyến thành công. Trải nghiệm Thương mại điện tử được tối ưu hóa hoàn toàn trên mọi thiết bị.",
    },
    {
      id: 3,
      img: img3,
      title: "Thiết kế Website",
      commentor: "Hòa Nguyễn ",
      date: "24-10-2022",
      tag: `wordpress, business, economy, design`,
      description1:
        "Nếu bạn muốn có một bộ đồ được thiết kế riêng, có lẽ bạn sẽ không lấy những mảnh được chuẩn bị trước và may chúng lại với nhau.",
      description2:
        "Mọi trang web, bất kể mục tiêu của trang web đó hay đối tượng mà nó phục vụ, sẽ được hưởng lợi từ màn hình hoạt động tốt trên các kích thước màn hình khác nhau và trên các thiết bị khác nhau. Đáp ứng là tính năng mà chúng tôi ưu tiên.",
      description3:
        "Xu hướng thiết kế luôn thay đổi và đội ngũ của chúng tôi sử dụng xu hướng mới nhất. Chúng tôi đã thử nghiệm và thử nghiệm quy trình thiết kế web để đảm bảo rằng mọi trang web chúng tôi sản xuất đều đặc biệt.",
      description4:
        "Chúng tôi chuyên tạo ra các trang web mang lại kết quả độc đáo, hấp dẫn và tuyệt vời. Thông qua chiến lược và thiết kế chuyên sâu, chúng tôi tập trung vào việc khai thác những gì tốt nhất của kỹ thuật số để thực hiện các mục tiêu chiến lược.",
    }    
  ];

  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  return {
    singleData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
  };
};

export default AllBlogData;
