import aboutTopImage from "../assets/img/home/creativity.svg";
import aboutTopImageMobile from "../assets/img/home/mph-mobile.svg"

const aboutData = () => {

}

const achievementsContent = [
        { title: "12", subTitle1: "năm", subTitle2: "kinh nghiệm" },
        { title: "97", subTitle1: "dự án", subTitle2: "hoàn thành" },
        { title: "81", subTitle1: "khách hàng", subTitle2: "thân thiện" },
        { title: "53", subTitle1: "giải", subTitle2: "thưởng" },
      ];

const educationContent = [
        {
          year: "",
          degree: "Khám phá",
          institute: "",
          details: `Phát triển sự hiểu biết thấu đáo về dự án, đối tượng và mục tiêu để xây dựng chiến lược kỹ thuật số. Phát triển khái niệm kỹ lưỡng, phân tích người dùng và các giao thức đảm bảo chất lượng, một thiết kế hấp dẫn mới có thể trở nên sống động. Mục tiêu của chúng tôi là đảm bảo rằng chúng tôi đáp ứng mọi nhu cầu sáng tạo của bạn. `,
        },
        {
          year: "",
          degree: "Phát triển",
          institute: "",
          details: `Làm cho thiết kế trở nên sống động dưới dạng một nguyên mẫu tương tác và chức năng. Xem lại, tinh chỉnh và tối ưu hóa. Kết hợp các thành phần triển khai và kỹ thuật vào một hệ thống chức năng cao, sẵn sàng để xem xét.`,
        },
        
      ];      

const experienceContent = [
        {
          year: "",
          position: "Sáng tạo",
          compnayName: "",
          details: `Mỗi dự án chúng tôi thực hiện cho khách hàng là một nỗ lực cá nhân để đáp ứng các yêu cầu với việc duy trì chất lượng cao nhất của sản phẩm. Chúng tôi chuyên giải quyết các vấn đề để tạo ra các thiết kế thông minh, mang lại trải nghiệm hấp dẫn và xây dựng các kết nối có ý nghĩa.`,
        },
        {
          year: "",
          position: "Chất lượng hơn số lượng",
          compnayName: "",
          details: `Chúng tôi tập trung thời gian và tài năng của mình vào ít dự án/khách hàng hơn. Đo lường thiết kế của mình bằng cách nó hoạt động thay vì chỉ đơn giản là nó trông như thế nào. Bố cục của mỗi trang sao cho thông tin chính được trình bày rõ ràng và trang không quá đông đúc.`,
        }
        
      ];

const personalInfoContent = [
        { meta: "first name", metaInfo: "Steve" },
        { meta: "last name", metaInfo: "Milner" },
        { meta: "Age", metaInfo: "27 Years" },
        { meta: "Nationality", metaInfo: "Tunisian" },
        { meta: "Freelance", metaInfo: "Available" },
        { meta: "Address", metaInfo: "Tunis" },
        { meta: "phone", metaInfo: "+21621184010" },
        { meta: "Email", metaInfo: "you@mail.com" },
        { meta: "Skype", metaInfo: " steve.milner" },
        { meta: "langages", metaInfo: "French, English" },
      ];

const skillsContent = [
        { skillClass: "p25", skillPercent: "25", skillName: "HTML" },
        { skillClass: "p89", skillPercent: "89", skillName: "JAVASCRIPT" },
        { skillClass: "p70", skillPercent: "70", skillName: "CSS" },
        { skillClass: "p66", skillPercent: "66", skillName: "PHP" },
        { skillClass: "p95", skillPercent: "95", skillName: "WORDPRESS" },
        { skillClass: "p50", skillPercent: "50", skillName: "JQUERY" },
        { skillClass: "p65", skillPercent: "65", skillName: "ANGULAR" },
        { skillClass: "p45", skillPercent: "45", skillName: "REACT" },
      ];

const aboutTop = {
        content: "Chúng tôi tự hào về những gì chúng tôi làm, tin tưởng vào công việc của chúng tôi và được thúc đẩy bởi sự thành công của khách hàng. Trong khi làm việc với <b>Minh Phúc Hân</b>, một điều mà bạn sẽ trải nghiệm nhiều nhất là sự khác biệt so với những người khác trong cách chúng ta làm việc và nhìn nhận mọi thứ, và tại sao sự khác biệt này lại quan trọng như vậy. <br/><br/>Ngay cả những việc nhỏ nhất được thực hiện khác nhau cũng có thể dẫn đến kết quả đặc biệt. Đây là điều thúc đẩy chúng ta làm mọi thứ theo cách chúng ta nghĩ là đúng. Điều này dẫn đến những đổi mới bạn tìm thấy tại <b>Minh Phúc Hân</b>.<br/><br/>Chúng tôi là một nhóm những người nghiện công nghệ trẻ tuổi, những người thích chơi với công nghệ mới. Chúng tôi liên tục thử nghiệm các công nghệ mới và triển khai chúng trong các dự án của mình để tạo ra kết quả lớn hơn. Chúng tôi cung cấp hầu hết tất cả các dịch vụ liên quan đến trang web và ứng dụng dành cho thiết bị di động."
}


export {
        aboutData,
        achievementsContent,
        educationContent,
        experienceContent,
        personalInfoContent,
        skillsContent,
        aboutTop,
        aboutTopImage,
        aboutTopImageMobile
}