import React from "react";

import CompaneyImage from "../../assets/img/home/team.svg"

const IntroContent = [
  {
    year: "",
    position: "ĐIỀU GÌ KHIẾN MINH PHÚC HÂN TRỞ NÊN KHÁC BIỆT",
    compnayName: "",
    details: `Khi bạn làm việc với chúng tôi, một điều mà bạn sẽ trải nghiệm nhiều nhất là sự khác biệt so với những người khác trong cách chúng ta làm việc và nhìn nhận mọi thứ. Chúng tôi giành được sự tin tưởng của bạn bằng cách nhạy bén, trực quan và trung thực.`,
  },
  {
    year: "",
    position: "CHÚNG TÔI LÀ GÌ",
    compnayName: "",
    details: `Chúng tôi là một nhóm gồm những người sáng tạo. Chúng tôi nghiêm túc trong việc mang lại kết quả tuyệt vời. Mục tiêu của chúng tôi trong vài năm tới là đưa sản phẩm của mình lên một tầm cao mới. Chúng tôi tin tưởng vào nhóm nhỏ - tác động lớn, vì vậy chúng tôi sẽ mở rộng nhóm của mình đến một giới hạn.`,
  }
]

const IntroComponent = () => {
  return (
    <ul>
      {IntroContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-info"></i>
          </div>
          {/* <span className="time open-sans-font text-uppercase">{val.year}</span> */}
          <h5 className="poppins-font text-uppercase">
            {val.position}
            {/* <span className="place open-sans-font">{val.compnayName}</span> */}
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export {
  IntroComponent,
  CompaneyImage
};
